
import { defineComponent, ref, reactive } from 'vue';
import { mapGetters, mapMutations } from 'vuex'
import { onClickOutside } from '@vueuse/core'
import { Button } from '@/components'
import Swal from 'sweetalert2'
import store from '@/store'

export default defineComponent({
  name : 'SubHeader',
  components: {
    Button
  },
  props:{
    isWatMonitor:{
      type: Boolean,
      default: false
    },
    isWatMonitorV3:{
      type: Boolean,
      default: false
    },
    isSharedReport:{
      type: Boolean,
      default: false
    },
    excelIsCompleted: {
      type: Boolean,
      default: false
    },
    excelInProgress: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'clientCreditsPlan',
      'isAuthenticated',
      'test',
      'reportsList',
      'reportTab',
      'isSuperAdmin',
      'isObserver',
      'canCreateStudy',
      'hasAccessToLogs'
    ]),
    breadCrumbs(){
      let breadCrumbs = []
      const routerName: any = this.$router.currentRoute.value.name ? this.$router.currentRoute.value.name : ''

      if(routerName != 'dashboard') breadCrumbs.push({name:'<i class="fas fa-home"></i>', link:'dashboard'})
      if(routerName == 'transactions') breadCrumbs.push({name:this.$t('suite_subheader_transactions')})
      if(routerName == 'settings') breadCrumbs.push({name:this.$t('suite_subheader_profile')})
      if(routerName.includes('newstudy')) breadCrumbs.push({name:this.$t('suite_subheader_new_study')})
      return breadCrumbs
    },
    showShareButton(){
      return this.test.testCommercialType != 'WAT_UX_QUANTI' && this.test.testCommercialType != 'WAT_UX'
    }
  },
  methods:{
    ...mapMutations({
      updateReportTab : 'updateReportTab'
    }),
    goBack(){
      this.$router.push({name:'dashboard'})
    },
    isDashboardView(){
      return this.$router.currentRoute.value.name == 'dashboard'
    },
    showBreadCrumbs(){
      return this.isAuthenticated
    },
    showSuiteLinks(){
      return !this.isWatMonitor
    },
    async openCreditsForm(){
      store.dispatch('createStatisticsClient',{concept:'MORE_CREDITS_POPUP_OPEN'})
      let vm = this
      Swal.fire({
        title: vm.$t('suite_swal_contact_title'),
        input: 'textarea',
        inputValue: vm.$t('suite_swal_contact_text'),
        icon:'info',
        confirmButtonText:vm.$t('suite_swal_contact_action'),
        showCloseButton: true,
        inputValidator: (value) => {
          if(!value) return vm.$t('suite_swal_credits_error')
          vm.sendContactForm(value, 'contactForCreditsPlan')
          return null
        }
      })
    },
    openContactForm(){
      let vm = this
      Swal.fire({
        title: vm.$t('suite_swal_contact_title'),
        input: 'textarea',
        inputPlaceholder: vm.$t('suite_swal_credits_text'),
        icon:'info',
        confirmButtonText:vm.$t('suite_swal_contact_action'),
        showCloseButton: true,
        inputValidator: (value) => {
          if(!value) return vm.$t('suite_swal_credits_error')
          vm.sendContactForm(value, 'contactForDiy')
          return null
        }
      })
    },
    generateExcelAggregated() {
      this.$emit('loaded-excel-aggregated');
      let vm = this
      Swal.fire({
        title: vm.$t('suite_info_generating_excel_aggregated_title'),
        html: vm.$t('suite_info_generating_excel_aggregated_text'),
        icon: 'success'
      });
    },
    async sendContactForm(message: string, storeActionName: string){
      let vm = this
      await store.dispatch(storeActionName,{message:message})
      .then((data) => {
        Swal.fire(
          vm.$t('suite_swal_contact_sent'),
          vm.$t('suite_swal_contact_sent_advert'),
          'success'
        )
      })
      .catch((response) => {
        Swal.fire(
          '',
          vm.$t('suite_swal_contact_sent_error'),
          'error'
        )
      })
    },
    navigateTo(breadCrumb: any, index: any){
      if(breadCrumb.link == 'dashboard') this.goBack()
    },
    downloadExcel(){
      if(this.isWatMonitorV3) return
      this.$emit('download-excel')
    }
  },
  setup() {
    const MonitorReports = ref(null)
    const showReportList = ref(false)
    onClickOutside(MonitorReports, (event) => showReportList.value = false)
    return { MonitorReports, showReportList }
  }
})
